import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import { SEO } from '../components/SEO';
import { SanityCallPage } from '../../graphql-types';
import { CalendlyEmbedLayout } from '../redesign';
import { H2 } from '@ph-bit/design.ui.typography.h2';
import { P2 } from '@ph-bit/design.ui.typography.p2';
interface PageData {
  page: Required<Pick<SanityCallPage, 'title' | 'description' | 'calendlyUrl'>>;
}

const FORM_CONTAINER_DIV = 'calendly-container';

export const query = graphql`
  query CallPageTemplateQuery($id: String!) {
    page: sanityCallPage(id: { eq: $id }) {
      title
      description
      calendlyUrl
    }
  }
`;

export const CallPageTemplate: React.FC<PageProps<PageData>> = ({
  data: {
    page: { title, description, calendlyUrl }
  }
}) => {
  useEffect(() => {
    window.Calendly.initInlineWidget({
      url: calendlyUrl,
      parentElement: document.getElementById(FORM_CONTAINER_DIV),
      utm: {}
    });
  }, [calendlyUrl]);

  return (
    <>
      <CalendlyEmbedLayout>
        <SEO pageTitle={title} pageDescription={description} />
        <div
          className={`flex-center flex-col mt-8 max-w-2xl w-full text-center mx-auto px-4`}
        >
          <H2>{title}</H2>
          <P2>{description}</P2>
        </div>
        <div id="calendly-container" style={{ height: 900 }} />
      </CalendlyEmbedLayout>
    </>
  );
};

export default CallPageTemplate;
